@use "../abstract" as *;
// Colors
$primary: $primary;
$secondary : $secondary;

$headingDark: $black;
$headingLight: $white;
$textDark:  rgba($black,.6);
$textLight: $white;

$body-color:                $textDark;
$headings-color:$headingDark;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary
);

$custom-colors:(
  "cornflower-blue" : $cornflower-blue,
  "big-stone":$big-stone,
  "heading-color":$big-stone,
  "text-color": $text-color
);
$theme-colors: map-merge($theme-colors, $custom-colors);


$border-radius:               0.3125rem;
$border-radius-sm:            0.5rem;
$border-radius-lg:            0.625rem;
// Fonts

// @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//-------------------------
//--- Theme Typography
//-------------------------
$enable-responsive-font-sizes: true;
$font-family-base:            $font-mulish;


//custom font-family
$btn-padding-x:               0.875rem;
$btn-font-family: $font-family-base;
$heading-font-family: $font-family-base;
$paragraph-font-family: $font-family-base;


$headings-font-weight:        700;
// $headings-line-height:        1.5;

//- Display Sizes

$display1-size:               6rem; // About 96px
$display2-size:               5rem; // About 80px
$display3-size:               3.75rem; // About 60px
$display4-size:               3.125rem; // About 50px


//- Font Sizes 
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                13px;

$h1-font-size:                5rem; //80px
$h2-font-size:                3.375rem; //54px
$h3-font-size:                2.5rem;  //40px
$h4-font-size:                1.375rem; //22px
$h5-font-size:                1.125rem; //18px
$h6-font-size:                0.75rem; //12px

$text-base:                  1rem;



// Form

$input-btn-padding-y:         0.75rem;
$input-btn-padding-x:         1.5625rem;
$input-btn-font-size:         $h6-font-size;
$input-btn-line-height:       1.88;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($primary, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    1.2;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
// $input-btn-line-height-lg:    $line-height-lg;

// $input-btn-border-width:      $border-width;


$input-border-radius:         0.3125rem; //8px
// $input-border-radius-lg:                $border-radius-lg;
// $input-border-radius-sm:                $border-radius-sm;

$form-group-margin-bottom: 1.25rem;
$input-height:             4.375rem; //70px
$label-margin-bottom : 0.9375rem;
$input-color: gray;
$custom-control-indicator-active-bg : $primary;
$input-placeholder-color: rgba(37, 55, 63, 0.7);
$input-border-color: rgba(128, 138, 142, 0.2);
$input-font-weight : 400;
$input-font-size: 1rem;
$input-padding-x:  0.9375rem;


// $input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       4.4375rem;
$input-padding-x-lg: 1.5625rem;
$input-font-size-lg: 1.125rem;
$input-padding-x-lg: 1.5625rem;



// $input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $input-border-color;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                0;

$form-check-input-checked-bg-color : $primary;


// Grid
$grid-breakpoints: ();
$grid-breakpoints: (
  xxs: 0,
  xss: 428px,
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);

// Fonts
$responsive-size: true;
$sizes: () ;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    100: 100%,
    100vh: 100vh,
  ),
  $sizes
);


$paragraph-margin-bottom:   0rem;

$headings-margin-bottom:      0 !default;
// Spacings


$spacer: 1rem;
$spacers: (
    0: 0,
    1: ($spacer * .3125),         // 5px
    2: ($spacer * .625),        // 10px  (now b 3)
    3: ($spacer *  1.0666),          // 15px  (now b 6)
    4: ($spacer * 1.25),        // 20px (now b around 8)
    5: ($spacer * 1.5),        // 24px (now b around 8)
    6: ($spacer * 1.875),         // 30px (now b around 13)
    7: ($spacer * 2.5),         // 40px (now b around 13)
    8: ($spacer * 3.125)         // 50px (now b around 13)
  );



$btn-focus-box-shadow: none;
$btn-box-shadow: none;
$link-decoration: none;
$link-hover-decoration: none;

$hr-margin-y: 0;

$card-border-radius: 10px;


$btn-padding-y:               0.9688rem ;
$btn-padding-x:               1.5625rem ;
$btn-font-size:               1.125rem ;
$btn-line-height:             line-height(27,18) ;
$btn-white-space:             null !default; 
